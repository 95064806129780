<template>
    <div v-if="isLoadingScreen" class="fixed inset-0 flex items-center justify-center bg-black z-50">
        <div role="status">
            <svg aria-hidden="true" class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor" />
                <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill" />
            </svg>
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    <div class="p-4 sm:ml-64">
        <div class="p-4">
            <div class="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                <div class="grid gap-6 mb-6 md:grid-cols-2">
                    <nav class="flex" aria-label="Breadcrumb">
                        <ol class="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                            <li class="inline-flex items-center">
                                <a href="#"
                                    class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
                                    <svg class="w-3 h-3 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                        fill="currentColor" viewBox="0 0 20 20">
                                        <path
                                            d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
                                    </svg>
                                    {{ breadCrumb.root.text }}
                                </a>
                            </li>
                            <li v-for="items in breadCrumb.child" :key="items.text">
                                <div class="flex items-center">
                                    <svg class="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                            stroke-width="2" d="m1 9 4-4-4-4" />
                                    </svg>
                                    <a href="#"
                                        class="ms-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ms-2 dark:text-gray-400 dark:hover:text-white">{{
                                            items.text
                                        }}</a>
                                </div>
                            </li>
                        </ol>
                    </nav>
                    <div class="flex justify-end">
                        <button @click="openModalCreate"
                            class="bg-blue-700 text-white px-2 py-1 rounded-md shadow-md hover:bg-gray-700 transition-colors duration-300">
                            <svg class="w-6 h-6 text-white-800 dark:text-white" aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                                viewBox="0 0 24 24">
                                <path fill-rule="evenodd"
                                    d="M9 7V2.221a2 2 0 0 0-.5.365L4.586 6.5a2 2 0 0 0-.365.5H9Zm2 0V2h7a2 2 0 0 1 2 2v6.41A7.5 7.5 0 1 0 10.5 22H6a2 2 0 0 1-2-2V9h5a2 2 0 0 0 2-2Z"
                                    clip-rule="evenodd" />
                                <path fill-rule="evenodd"
                                    d="M9 16a6 6 0 1 1 12 0 6 6 0 0 1-12 0Zm6-3a1 1 0 0 1 1 1v1h1a1 1 0 1 1 0 2h-1v1a1 1 0 1 1-2 0v-1h-1a1 1 0 1 1 0-2h1v-1a1 1 0 0 1 1-1Z"
                                    clip-rule="evenodd" />
                            </svg>

                        </button>
                    </div>
                </div>

                <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                    <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" class="px-6 py-3">
                                    Id
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Name
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Target
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Accumulated
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Category
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="bg-white  dark:bg-gray-800 " v-for="item in getListDonasi" :key="item.id">
                                <td scope="row" class="px-6 py-4 font-medium text-gray-900 text-warp dark:text-white">
                                    {{ item.id }}
                                </td>
                                <td scope="row" class="px-6 py-4 font-medium text-gray-900 text-warp dark:text-white">
                                    {{ item.name }}
                                </td>
                                <td scope="row" class="px-6 py-4 font-medium text-gray-900 text-warp dark:text-white">
                                    {{ item.target }}
                                </td>
                                <td scope="row" class="px-6 py-4 font-medium text-gray-900 text-warp dark:text-white">
                                    {{ item.accumulated }}
                                </td>
                                <td class="px-6 py-4 ">
                                    {{ item.category }}
                                </td>
                                <td class="flex px-6 py-4">
                                    <span class="mr-2">
                                        <button
                                            @click="openModalUpdate(item.id, item.name, item.description, item.target, item.accumulated, item.thumbnail, item.image, item.category)"
                                            class="block text-white bg-white-700  font-medium rounded-lg text-sm py-2.5 text-center"
                                            type=" button">
                                            <span>
                                                <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    fill="currentColor" viewBox="0 0 24 24">
                                                    <path fill-rule="evenodd"
                                                        d="M11.32 6.176H5c-1.105 0-2 .949-2 2.118v10.588C3 20.052 3.895 21 5 21h11c1.105 0 2-.948 2-2.118v-7.75l-3.914 4.144A2.46 2.46 0 0 1 12.81 16l-2.681.568c-1.75.37-3.292-1.263-2.942-3.115l.536-2.839c.097-.512.335-.983.684-1.352l2.914-3.086Z"
                                                        clip-rule="evenodd" />
                                                    <path fill-rule="evenodd"
                                                        d="M19.846 4.318a2.148 2.148 0 0 0-.437-.692 2.014 2.014 0 0 0-.654-.463 1.92 1.92 0 0 0-1.544 0 2.014 2.014 0 0 0-.654.463l-.546.578 2.852 3.02.546-.579a2.14 2.14 0 0 0 .437-.692 2.244 2.244 0 0 0 0-1.635ZM17.45 8.721 14.597 5.7 9.82 10.76a.54.54 0 0 0-.137.27l-.536 2.84c-.07.37.239.696.588.622l2.682-.567a.492.492 0 0 0 .255-.145l4.778-5.06Z"
                                                        clip-rule="evenodd" />
                                                </svg>
                                            </span>
                                        </button>
                                    </span>
                                    <span>
                                        <button @click="deleteData(item.id)"
                                            class="block text-white bg-white-700  font-medium rounded-lg text-sm py-2.5 text-center"
                                            type=" button">
                                            <span>
                                                <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    fill="currentColor" viewBox="0 0 24 24">
                                                    <path fill-rule="evenodd"
                                                        d="M8.586 2.586A2 2 0 0 1 10 2h4a2 2 0 0 1 2 2v2h3a1 1 0 1 1 0 2v12a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8a1 1 0 0 1 0-2h3V4a2 2 0 0 1 .586-1.414ZM10 6h4V4h-4v2Zm1 4a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Zm4 0a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Z"
                                                        clip-rule="evenodd" />
                                                </svg>
                                            </span>
                                        </button>
                                    </span>
                                    <span>
                                        <div class="fixed inset-0 bg-black z-50" v-if="isModalUpdateOpen">
                                            <div aria-hidden="true"
                                                class="fixed inset-0 flex items-center justify-center">
                                                <div class="relative p-4 w-full max-w-2xl">
                                                    <!-- Modal content -->
                                                    <div class="relative bg-white rounded-lg shadow">
                                                        <!-- Modal header -->
                                                        <div
                                                            class="flex items-center justify-between p-4 border-b rounded-t">
                                                            <h3 class="text-xl font-semibold text-gray-900">
                                                                Update</h3>
                                                            <button type="button" @click="closeModalUpdate"
                                                                class="text-gray-400 bg-transparent rounded-lg w-8 h-8">
                                                                <svg class="w-3 h-3" aria-hidden="true" fill="none"
                                                                    viewBox="0 0 14 14">
                                                                    <path stroke="currentColor" stroke-linecap="round"
                                                                        stroke-linejoin="round" stroke-width="2"
                                                                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                                                </svg>
                                                                <span class="sr-only">Close modal</span>
                                                            </button>
                                                        </div>
                                                        <!-- Modal body -->
                                                        <div class="p-4 space-y-4">
                                                            <div>
                                                                <div class="max-h-full">
                                                                    <label class="cursor-pointer">
                                                                        <div v-if="isLoading"
                                                                            class="flex items-center justify-center w-full h-32">
                                                                            <svg class="animate-spin h-10 w-10 text-gray-500"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                fill="none" viewBox="0 0 24 24">
                                                                                <circle class="opacity-25" cx="12"
                                                                                    cy="12" r="10" stroke="currentColor"
                                                                                    stroke-width="4"></circle>
                                                                                <path class="opacity-75"
                                                                                    fill="currentColor"
                                                                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A8.001 8.001 0 0112 4v4a4 4 0 00-4 4H6v4.291zM20 12a8 8 0 01-8 8v-4a4 4 0 004-4h4zm-2-5.709A8.001 8.001 0 0112 20V16a4 4 0 004-4h4V6.291z">
                                                                                </path>
                                                                            </svg>
                                                                        </div>
                                                                        <div class="w-full max-w-7xl mx-auto px-4">
                                                                            <!-- Container lebih besar dan responsif -->
                                                                            <form action="" class="space-y-6">
                                                                                <div class="flex flex-wrap space-x-6">
                                                                                    <div class="flex-1">
                                                                                        <label for="default-input"
                                                                                            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                                                                            Name
                                                                                        </label>
                                                                                        <input v-model="form.name"
                                                                                            type="text"
                                                                                            id="default-input"
                                                                                            class="form-input w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                                                                    </div>
                                                                                    <div class="flex-1">
                                                                                        <label for="default-input"
                                                                                            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                                                                            Category
                                                                                        </label>
                                                                                        <input v-model="form.category"
                                                                                            type="text"
                                                                                            id="default-input"
                                                                                            class="form-input w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="flex flex-wrap space-x-6">
                                                                                    <div class="flex-1">
                                                                                        <label for="default-input"
                                                                                            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                                                                            Target
                                                                                        </label>
                                                                                        <input v-model="form.target"
                                                                                            type="text"
                                                                                            id="default-input"
                                                                                            class="form-input w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                                                                    </div>
                                                                                    <div class="flex-1">
                                                                                        <label for="default-input"
                                                                                            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                                                                            Accumulated
                                                                                        </label>
                                                                                        <input
                                                                                            v-model="form.accumulated"
                                                                                            type="text"
                                                                                            id="default-input"
                                                                                            class="form-input w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                                                                    </div>
                                                                                    <div class="flex-1">
                                                                                        <label for="default-input"
                                                                                            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                                                                            Image
                                                                                        </label>
                                                                                        <input v-model="form.image"
                                                                                            type="text"
                                                                                            id="default-input"
                                                                                            class="form-input w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                                                                    </div>
                                                                                </div>
                                                                                <div>
                                                                                    <label for="message"
                                                                                        class="flex mb-2 text-sm font-medium text-gray-900 dark:text-white">Description</label>
                                                                                    <TinyMCE v-model="form.description">
                                                                                    </TinyMCE>
                                                                                </div>
                                                                            </form>
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- Modal footer -->
                                                        <div class="flex items-center p-4 border-t rounded-b">
                                                            <span>
                                                                <button @click="edit" type="button"
                                                                    class="text-white bg-blue-800 hover:bg-blue-400 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-400 dark:hover:bg-gray-400 dark:focus:ring-gray-400">
                                                                    Update
                                                                </button>
                                                            </span>
                                                            <button @click="closeModalUpdate" type="button"
                                                                class="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                                                                Decline
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </span>
                                    <span>
                                        <div class="fixed inset-0 bg-black z-50" v-if="isModalCreateOpen">
                                            <div aria-hidden="true"
                                                class="fixed inset-0 flex items-center justify-center">
                                                <div class="relative p-4 w-full max-w-2xl">
                                                    <!-- Modal content -->
                                                    <div class="relative bg-white rounded-lg shadow">
                                                        <!-- Modal header -->
                                                        <div
                                                            class="flex items-center justify-between p-4 border-b rounded-t">
                                                            <h3 class="text-xl font-semibold text-gray-900">
                                                                Create</h3>
                                                            <button type="button" @click="closeModalCreate"
                                                                class="text-gray-400 bg-transparent rounded-lg w-8 h-8">
                                                                <svg class="w-3 h-3" aria-hidden="true" fill="none"
                                                                    viewBox="0 0 14 14">
                                                                    <path stroke="currentColor" stroke-linecap="round"
                                                                        stroke-linejoin="round" stroke-width="2"
                                                                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                                                </svg>
                                                                <span class="sr-only">Close modal</span>
                                                            </button>
                                                        </div>
                                                        <!-- Modal body -->
                                                        <div class="p-4 space-y-4">
                                                            <div>
                                                                <div class="max-h-full">
                                                                    <label class="cursor-pointer">
                                                                        <div v-if="isLoading"
                                                                            class="flex items-center justify-center w-full h-32">
                                                                            <svg class="animate-spin h-10 w-10 text-gray-500"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                fill="none" viewBox="0 0 24 24">
                                                                                <circle class="opacity-25" cx="12"
                                                                                    cy="12" r="10" stroke="currentColor"
                                                                                    stroke-width="4"></circle>
                                                                                <path class="opacity-75"
                                                                                    fill="currentColor"
                                                                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A8.001 8.001 0 0112 4v4a4 4 0 00-4 4H6v4.291zM20 12a8 8 0 01-8 8v-4a4 4 0 004-4h4zm-2-5.709A8.001 8.001 0 0112 20V16a4 4 0 004-4h4V6.291z">
                                                                                </path>
                                                                            </svg>
                                                                        </div>
                                                                        <div class="w-full max-w-7xl mx-auto px-4">
                                                                            <!-- Container lebih besar dan responsif -->
                                                                            <form @submit.prevent="create"
                                                                                @keydown.enter.prevent="">
                                                                                <div class="flex flex-wrap space-x-6">
                                                                                    <div class="flex-1">
                                                                                        <label for="default-input"
                                                                                            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                                                                            Name
                                                                                        </label>
                                                                                        <input v-model="form2.name"
                                                                                            type="text"
                                                                                            id="default-input"
                                                                                            class="form-input w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                                                                    </div>
                                                                                    <div class="flex-1">
                                                                                        <label for="default-input"
                                                                                            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                                                                            Target
                                                                                        </label>
                                                                                        <input v-model="form2.target"
                                                                                            type="text"
                                                                                            id="default-input"
                                                                                            class="form-input w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="flex flex-wrap space-x-6">
                                                                                    <div class="flex-1">
                                                                                        <label for="default-input"
                                                                                            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                                                                            Category
                                                                                        </label>
                                                                                        <input v-model="form2.category"
                                                                                            type="text"
                                                                                            id="default-input"
                                                                                            class="form-input w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                                                                    </div>
                                                                                    <div class="flex-1">
                                                                                        <label for="default-input"
                                                                                            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                                                                            Image
                                                                                        </label>
                                                                                        <input v-model="form2.image"
                                                                                            type="text"
                                                                                            id="default-input"
                                                                                            class="form-input w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                                                                    </div>
                                                                                </div>

                                                                                <div>
                                                                                    <label for="message"
                                                                                        class="flex mb-2 text-sm font-medium text-gray-900 dark:text-white">Description</label>
                                                                                    <TinyMCE
                                                                                        v-model="form2.description">
                                                                                    </TinyMCE>
                                                                                </div>
                                                                            </form>
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- Modal footer -->
                                                        <div class="flex items-center p-4 border-t rounded-b">
                                                            <span>
                                                                <button @click="create" type="button"
                                                                    class="text-white bg-blue-800 hover:bg-blue-400 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-400 dark:hover:bg-gray-400 dark:focus:ring-gray-400">
                                                                    Create
                                                                </button>
                                                            </span>
                                                            <button @click="closeModalCreate" type="button"
                                                                class="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                                                                Decline
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Swal from 'sweetalert2'
import { format } from 'date-fns'
import { id } from 'date-fns/locale'

export default {
    data() {
        return {
            url_image: require("@/assets/image-1@2x.jpg"),
            isLoading: false,
            isLoadingScreen: false,
            isModalUpdateOpen: false,
            isModalCreateOpen: false,
            agensi: null,
            breadCrumb: {
                root: { text: "Home", url: "" },
                child: [
                    { text: "Donation", url: "" },
                    { text: "List", url: "" }
                ]
            },
            pageInfo: {
                totalPages: 0,
                pageNumber: 0,
                visiblePages: 5,
                pageSize: 5,
                totalElements: 0,
            },
            form: {
                id: "",
                name: "",
                description: "",
                target: 0,
                accumulated: 0,
                thumbnail: "",
                image: "",
                category: ""
            },
            form2: {
                name: "",
                description: "",
                target: 0,
                accumulated: 0,
                thumbnail: "",
                image: "",
                category: ""
            }
        }
    },
    created() {
        var parameters = this.$route.params
        this.fetchData(parameters.agensi, parseInt(parameters.pageNumber))
    },
    computed: {
        getListDonasi() {
            return this.$store.getters['donasi/getListDonasi']
        },
    },
    methods: {
        formatDate(date) {
            return format(new Date(date), 'EEEE, dd MMMM yyyy', { locale: id });
        },
        async fetchData(agensi, pageNumber) {
            this.pageInfo.pageNumber = pageNumber
            this.agensi = agensi
            await this.$store.dispatch('donasi/listDonasi', {
                index: (this.pageInfo.pageNumber - 1),
                limit: this.pageInfo.pageSize,
            }).then(() => {
                this.isLoadingScreen = true
            }).finally(() => {
                this.isLoadingScreen = false
            })
        },
        edit() {
            const htmlContent = this.form.description
            const textContent = htmlContent.replace(/<[^>]+>/g, '').replace(/&nbsp;/g, '').trim()

            if (this.form.name === "" || this.form.target === 0 || this.form.target === "" || this.form.category === ""
                || this.form.image === "" || textContent.length === 0) {
                Swal.fire({
                    title: "Warning",
                    text: "Name Or Target Or Category Or Image Or Description cannot be Empty",
                    icon: "warning"
                })
            } else {
                Swal
                    .fire({
                        title: "Are you sure?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes, Edit",
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            this.isLoading = true
                            this.$store.dispatch('donasi/editDonasi', {
                                id: this.form.id,
                                name: this.form.name,
                                description: this.form.description,
                                target: this.form.target,
                                accumulated: this.form.accumulated,
                                thumbnail: this.form.image,
                                image: this.form.image,
                                category: this.form.category
                            }).then(() => {
                                Swal.fire({
                                    title: "Edit Success",
                                    text: "Sukses Edit",
                                    icon: "success"
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        location.reload()
                                    }
                                })
                            }).catch((error) => {
                                Swal.fire({
                                    title: "Error",
                                    text: "Gagal Edit: " + error,
                                    icon: "error"
                                })
                            })
                        }
                    })
            }
        },
        create() {
            const htmlContent = this.form2.description
            const textContent = htmlContent.replace(/<[^>]+>/g, '').replace(/&nbsp;/g, '').trim()

            if (this.form2.name === "" || this.form2.target === 0 || this.form2.target === "" || this.form2.category === ""
                || this.form2.image === "" || textContent.length === 0) {
                Swal.fire({
                    title: "Warning",
                    text: "Name Or Target Or Category Or Image Or Description cannot be Empty",
                    icon: "warning"
                })
            } else {
                Swal
                    .fire({
                        title: "Are you sure?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes, Create",
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            this.isLoading = true
                            this.$store.dispatch('donasi/createDonasi', {
                                name: this.form2.name,
                                description: this.form2.description,
                                target: this.form2.target,
                                accumulated: this.form2.accumulated,
                                thumbnail: this.form2.image,
                                image: this.form2.image,
                                category: this.form2.category
                            }).then(() => {
                                Swal.fire({
                                    title: "Create Success",
                                    text: "Sukses Create",
                                    icon: "success"
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        location.reload()
                                    }
                                })
                            }).catch((error) => {
                                Swal.fire({
                                    title: "Error",
                                    text: "Gagal Create: " + error,
                                    icon: "error"
                                })
                            })
                        }
                    })
            }
        },
        deleteData(id) {
            Swal.fire({
                title: "Maintenance",
                text: "This Function Under Maintenance " + id,
                icon: "success"
            }).then((result) => {
                if (result.isConfirmed) {
                    this.isLoadingScreen = false
                    location.reload()
                }
            })
            // Swal
            //     .fire({
            //         title: "Are you sure?",
            //         icon: "warning",
            //         showCancelButton: true,
            //         confirmButtonColor: "#3085d6",
            //         cancelButtonColor: "#d33",
            //         confirmButtonText: "Yes, Delete",
            //     })
            //     .then((result) => {
            //         if (result.isConfirmed) {
            //             this.isLoadingScreen = true
            //             this.$store.dispatch('donasi/deleteDonasi', {
            //                 id: id
            //             }).then(() => {
            //                 Swal.fire({
            //                     title: "Delete Success",
            //                     text: "Sukses Hapus Donasi!",
            //                     icon: "success"
            //                 }).then((result) => {
            //                     if (result.isConfirmed) {
            //                         this.isLoadingScreen = false
            //                         location.reload()
            //                     }
            //                 })
            //             }).catch((error) => {
            //                 Swal.fire({
            //                     title: "Error",
            //                     text: "Gagal Hapus Donasi: " + error,
            //                     icon: "error"
            //                 })
            //             })
            //         }
            //     })
        },
        openModalUpdate(id, name, description, target, accumulated, thumbnail, image, category) {
            this.form.id = id
            this.form.name = name
            this.form.description = description
            this.form.target = target
            this.form.accumulated = accumulated
            this.form.thumbnail = thumbnail
            this.form.image = image
            this.form.category = category
            this.isModalUpdateOpen = true
        },
        closeModalUpdate() {
            this.form.id = ""
            this.form.name = ""
            this.form.description = ""
            this.form.target = 0
            this.form.accumulated = 0
            this.form.thumbnail = ""
            this.form.image = ""
            this.form.category = ""
            this.isModalUpdateOpen = false
        },
        openModalCreate() {
            this.isModalCreateOpen = true
        },
        closeModalCreate() {
            this.form2.name = ""
            this.form2.description = ""
            this.form2.target = 0
            this.form2.accumulated = 0
            this.form2.thumbnail = ""
            this.form2.image = ""
            this.form2.category = ""
            this.isModalCreateOpen = false
        },
    }
}
</script>